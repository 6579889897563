import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

import margarethowell from '../images/logo-round.png'

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className='header'>
      <div className='header__inner'>
        {/* <StaticImage
          className="rotate"
          src="../images/logo-round.png"
          backgroundColor="transparent"
          loading="eager"
          width={400}
          quality={400}
          formats={["auto", "webp", "avif","png", "jpg"]}
          alt=""
          placeholder= 'none'
          style={{ marginBottom: `var(--space-3)` }}
        /> */}

        <img className="rotate" src={margarethowell} alt="Atypical Kitl" width="400" height="100%" />

        <h1>Inspire Movement.</h1>
        <h2>
          Site under development, in beta testing.
        </h2>
      </div>
    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
